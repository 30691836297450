<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
        @onRelatedCommunityLoaded="relatedCommunityLoaded"
        @onRelatedCommunityLoading="relatedCommunitiesLoading = true"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card ref="activeSection" elevation="0" outlined color="#f1f1f1">
        <v-card-title>Edit User</v-card-title>
        <v-card-text>
          <!-- Start Edit User section -->
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="userToEdit.firstName"
                    label="First Name"
                    :error-messages="firstNameErrors"
                    @input="$v.userToEdit.firstName.$touch()"
                    @blur="$v.userToEdit.firstName.$touch()"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="userToEdit.lastName"
                    label="Last Name"
                    :error-messages="lastNameErrors"
                    @input="$v.userToEdit.lastName.$touch()"
                    @blur="$v.userToEdit.lastName.$touch()"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="userToEdit.email"
                    label="Email"
                    :error-messages="emailErrors"
                    @input="$v.userToEdit.email.$touch()"
                    @blur="$v.userToEdit.email.$touch()"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="userToEdit.role"
                    :items="roleItems"
                    item-text="name"
                    item-value="value"
                    label="Choose role..."
                    :error-messages="roleErrors"
                    @input="$v.userToEdit.role.$touch()"
                    @blur="$v.userToEdit.role.$touch()"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="isResident(userToEdit.role)">
                <v-col cols="12" md="6">
                  <v-textarea
                    v-model="userToEdit.address"
                    label="Address"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="userCommunities"
                    :items="relatedCommunities"
                    :loading="relatedCommunitiesLoading"
                    item-text="communityName"
                    item-value="aspireId"
                    label="Choose communities..."
                    dense
                    multiple
                    solo
                    :error-messages="communitiesErrors"
                    :rules="[
                      (userCommunities && userCommunities.length > 0) ||
                        'Choose at least one community'
                    ]"
                    @input="$v.userCommunities.$touch()"
                    @blur="$v.userCommunities.$touch()"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="userToEdit.password"
                    label="Password"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append.prevent="showPassword = !showPassword"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  ></v-text-field>
                  <p>
                    Password must be minimum of eight characters with at least
                    one capital and one lower case letter.
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="userToEdit.confirmPassword"
                    label="Confirm Password"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append.prevent="showPassword = !showPassword"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[
                      userToEdit.password === userToEdit.confirmPassword ||
                        'Passwords do not match'
                    ]"
                    required
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <div class="mt-2">
                <v-btn class="orange-bg white--text mx-2" @click="editUser">
                  Update User
                </v-btn>
                <v-btn
                  class="orange-bg white--text mx-2"
                  @click="statusDialog = true"
                >
                  {{ userToEdit.isActive ? "Suspend" : "Resume" }}
                </v-btn>
                <v-btn
                  class="orange-bg white--text mx-2"
                  @click="deleteDialog = true"
                >
                  Delete User
                </v-btn>
                <v-btn class="orange-bg white--text mx-2" @click="cancel">
                  Cancel
                </v-btn>
              </div>
            </v-container>
          </v-form>
          <!-- End Edit User section -->
        </v-card-text>
      </v-card>

      <!-- </v-container> -->
    </v-skeleton-loader>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          Delete User Access
        </v-card-title>
        <v-card-text
          >Are you sure you want to delete user access:
          <p>{{ userToEdit.fullName - userToEdit.email }}</p></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!statusProcessing"
            color="green darken-1"
            text
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :loading="statusProcessing"
            color="green darken-1"
            text
            @click="deleteUserAccess()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="statusDialog" persistent max-width="290">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text
          >Are you sure you want to
          {{ userToEdit.isActive ? "suspend" : "resume" }} access for
          {{ userToEdit.fullName }}
          ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!statusProcessing"
            color="green darken-1"
            text
            @click="statusDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="toggleStatus()"
            :loading="statusProcessing"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import {
  GET_QUERY,
  POST_DATA,
  API_CUSTOMERS,
  API_USERS,
  PUT_DATA,
  DELETE,
  API_USER_INFO
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
import { required } from "vuelidate/lib/validators";
// import moment from "moment";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    statusProcessing: false,
    deleteDialog: false,
    statusDialog: false,
    communityInfo: {},
    relatedCommunitiesLoading: false,
    relatedCommunities: [],
    showPassword: false,
    userToEdit: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: "",
      communities: [],
      address: ""
    },
    role: "",
    roleItems: [
      { name: "Property Manager", value: "Property Manager" },
      { name: "Board Member", value: "Board Member" },
      { name: "Landscape Committee", value: "Landscape Committee" },
      { name: "Tenant/Resident", value: "Tenant/Resident" },
      {
        name: "Tenant/Resident - Neighborhood",
        value: "Tenant/Resident - Neighborhood"
      }
    ],
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    userProcessed: false,
    communitiesToProcess: [],
    userCommunities: []
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    },
    userId: {
      type: [String],
      default: ""
    }
  },
  validations() {
    // return true;
    return {
      userCommunities: {
        required
      },
      userToEdit: {
        firstName: {
          required
        },
        lastName: {
          required
        },
        role: {
          required
        },
        email: {
          required
        }
        // password: {
        //   required
        // },
        // confirmPassword: {
        //   required
        // },
        // communities: {
        //   required
        // }
      }
    };
  },
  watch: {
    communitiesToProcess: async function() {
      if (this.communitiesToProcess.length == 0) return;

      let processed = true;
      this.communitiesToProcess.forEach(function(elem) {
        processed = processed && elem.processed;
      });

      if (processed) {
        this.$router.push({
          name: "ManagePortalAccess",
          params: { customerId: this.customerId }
        });
      }
    }
  },
  async mounted() {},
  async created() {
    let self = this;
    self.loading = true;
    await self.getComunityInfo();
    await self.getUser();
    self.loading = false;
  },
  methods: {
    isResident(role) {
      return role == "Tenant/Resident";
    },
    cancel() {
      this.$router.push({
        name: "ManagePortalAccess",
        params: { customerId: this.customerId }
      });
    },
    deleteUserAccess() {
      let self = this;
      if (!this.userToEdit) return;

      this.statusProcessing = true;

      this.$store
        .dispatch(DELETE, {
          listName: `${API_CUSTOMERS}/${self.customerId}/user`,
          id: self.userId
        })
        .then(function() {
          self.statusProcessing = false;
          self.userToEdit.deleteDialog = false;
          self.cancel();
        });
    },
    toggleStatus() {
      if (this.statusProcessing) return;

      this.statusProcessing = true;

      // item.isActive = !item.isActive;

      let url = `${API_USER_INFO}/${this.userId}/${
        this.userToEdit.isActive ? "suspend" : "activate"
      }`;
      this.$store
        .dispatch(POST_DATA, {
          listName: url
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.userToEdit.isActive = response.data.isActive;
            this.$snackbar.showMessage({
              content: "Member has been updated"
            });
          }
          this.statusProcessing = false;
          this.statusDialog = false;
        });
    },
    async changePassword(item, password, newPassword) {
      if (password != newPassword) {
        this.$snackbar.showMessage({
          content: "Passwords don't match.",
          color: "red"
        });
        return;
      }

      let updatePasswordInfo = {
        identityId: item.id,
        password: newPassword
      };

      let self = this;
      await this.$store
        .dispatch(PUT_DATA, {
          listName: `${API_USER_INFO}/${item.id}/password`,
          data: updatePasswordInfo
        })
        .then(response => {
          // if (response && (response.status >= 200 || response.status <= 204))
          if (response) {
            self.$snackbar.showMessage({
              content: "Password has been updated.",
              color: "green"
            });
            self.password = "";
            self.confirmPassword = "";
            item.changePasswordDialog = false;
          }
        });
    },
    relatedCommunityLoaded(communities) {
      this.relatedCommunities = communities;
      this.relatedCommunitiesLoading = false;

      // const parsedCustomerId = parseInt(this.customerId);
      // if (!isNaN(parsedCustomerId))
      //   this.userToEdit.communities.push(parsedCustomerId);
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      this.loading = true;

      let self = this;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.communityInfo = crud.getPopulatedComunityInfo(response.data);
            self.createBreadcrumb();
            self.setInternalBreadcrumbs();
          } else {
            self.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive Community details. Something went wrong!",
              color: "red"
            });
          }

          self.loading = false;
        });
    },
    async getUser() {
      this.loading = true;

      let self = this;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: `${API_USERS}/internalUsers`,
          id: this.userId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.userToEdit = response.data;
            self.userCommunities = self.userToEdit.communityIds.$values.map(
              el => el
            );
            // self.userToEdit.communities = self.userToEdit.communityIds.$values;
          } else {
            self.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive User details. Something went wrong!",
              color: "red"
            });
          }

          self.loading = false;
        });
    },
    editUser() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      // this.userToEdit.userType = "Customer";
      this.userToEdit.role = this.userToEdit.role;
      // this.userToEdit.userName = this.userToEdit.email;

      if (this.userCommunities.length == 0) {
        const parsedCustomerId = parseInt(this.customerId);
        if (isNaN(parsedCustomerId)) {
          self.$snackbar.showMessage({
            content: "No community is specified.",
            color: "red"
          });
          return;
        }
        this.userCommunities.push(parsedCustomerId);
      }

      // let userCommunities = this.userToEdit.communities.map(el => el);
      // this.userToEdit.communities = [];
      // this.userToEdit.properties = this.userToEdit.communities.map(el => {
      //   return { id: el };
      // });

      let self = this;
      self.$store
        .dispatch(PUT_DATA, {
          listName: `${API_USERS}/${self.userId}`,
          data: self.userToEdit
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            if (response.data.error) {
              if (
                response.data.error ===
                "Cannot create user, email already in use."
              )
                self.snackbarContent =
                  "Cannot create user, email already in use.";
              else
                self.snackbarContent =
                  "User cannot be created. Email might be already in use. Also please check that password contains at least 8 symbols and include at least one uppercase letter, one lowercase letter.";
              self.snackbarColor = "red";
            } else {
              self.snackbarContent = "User has been updated";

              // let user = response.data;

              self.communitiesToProcess = self.userCommunities.map(i => {
                return { id: i, processed: false };
              });

              self.userProcessed = true;

              self.relatedCommunities.forEach(community => {
                if (
                  !self.userCommunities.find(el => el == community.aspireId)
                ) {
                  this.$store
                    .dispatch(DELETE, {
                      listName: `${API_CUSTOMERS}/${community.aspireId}/user`,
                      id: self.userId
                    })
                    .then(function() {});
                }
              });

              self.userCommunities.forEach(element => {
                self.$store
                  .dispatch(POST_DATA, {
                    listName: `${API_CUSTOMERS}/${element}/user/${this.userId}`
                  })
                  .then(addResponse => {
                    if (
                      addResponse.status >= 200 &&
                      addResponse.status <= 204
                    ) {
                      self.snackbarColor = "green";
                      self.snackbarContent =
                        "User has been added to " +
                        self.relatedCommunities.find(
                          el => el.aspireId == element
                        ).communityName;

                      self.communitiesToProcess.find(
                        elem => elem.id == element
                      ).processed = true;

                      self.communitiesToProcess.push({
                        id: new Date(),
                        processed: true
                      });
                    }
                    self.$snackbar.showMessage({
                      content: self.snackbarContent,
                      color: self.snackbarColor
                    });
                  });
              });
            }
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });
        })
        .catch(res => {
          let error =
            "User cannot be created. Email might be already in use. Also please check that password contains at least 8 symbols and include at least one uppercase letter, one lowercase letter.";
          if (res)
            error =
              "User cannot be created. Email might be already in use. Also please check that password contains at least 8 symbols and include at least one uppercase letter, one lowercase letter.";

          self.$snackbar.showMessage({
            content: error,
            color: "red"
          });
        });

      // this.saveCustomer();
    },
    setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Manage Portal Access",
          route: "/customers/" + this.customerId + "/manage-portal-access"
        },
        { title: "Edit User" }
      ];
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Manage Portal Access",
          route: "/customers/" + this.customerId + "/manage-portal-access"
        },
        { title: "Edit User" }
      ]);
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      return dateFormatter.formatDateUs(date);
      // return moment(date).format("MM/DD/YYYY");
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {
    firstNameErrors() {
      const errors = [];
      if (!this.$v.userToEdit.firstName.$dirty) return errors;
      !this.$v.userToEdit.firstName.required && errors.push("Required.");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.userToEdit.lastName.$dirty) return errors;
      !this.$v.userToEdit.lastName.required && errors.push("Required.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.userToEdit.password.$dirty) return errors;
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.userToEdit.confirmPassword.$dirty) return errors;
      return errors;
    },
    roleErrors() {
      const errors = [];
      if (!this.$v.userToEdit.role.$dirty) return errors;
      !this.$v.userToEdit.role.required && errors.push("Required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.userToEdit.email.$dirty) return errors;
      !this.$v.userToEdit.email.required && errors.push("Required.");
      return errors;
    },
    communitiesErrors() {
      const errors = [];
      if (!this.$v.userCommunities.$dirty) return errors;
      !this.$v.userCommunities.required && errors.push("Required.");
      return errors;
    }
  }
};
</script>
